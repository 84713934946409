import React from 'react'
import { Grid, Segment, Image, Icon, Header, List } from 'semantic-ui-react'
import Obfuscate from 'react-obfuscate'

import Layout from '../components/layout'
import {
  LayoutSection,
  primaryLayoutColor,
  secondaryLayoutColor,
  JustifyParagraph,
  RightParagraph,
  BlackLink,
  CompactRow,
  NoMarginSegment,
} from '../components/layout-components'

import RonaldImg from '../images/Ronald-Steinke.jpg'

const IndexPage = () => (
  <Layout>
    {/* Main Info */}
    <LayoutSection
      color={primaryLayoutColor}
      header={<h1>Ronald Steinke, IoT&#8209;Architekt</h1>}
      text
    >
      <Image src={RonaldImg} circular size="small" floated="right" />
      <Segment textAlign="left" vertical>
        <JustifyParagraph>
          Hallo, ich bin Ronald Steinke. Ich komme aus dem Bereich der
          Anwendungs&shy;forschung im IoT-Sektor. Jetzt bin ich dabei, Sie bei
          der Digi&shy;ta&shy;lisierung zu unterst&uuml;tzen.
        </JustifyParagraph>
        <JustifyParagraph>
          Meine Leiden&shy;schaft ist das Erarbeiten von angepassten
          L&ouml;sungen im Bereich der Automa&shy;tisierung und
          Visua&shy;lisierung von Arbeits&shy;prozessen. In meiner vorigen
          T&auml;tigkeit als Wissen&shy;schaft&shy;licher Mit&shy;arbeiter
          konnte ich von der Forschungs&shy;seite aus in viele Prozesse
          reinschnuppern und M&ouml;glichkeiten der effektiven Gestaltung der
          Zukunft erarbeiten.
        </JustifyParagraph>
        <JustifyParagraph>
          Ich hoffe, ich kann Ihnen bei Ihrem Weg behilflich sein und freue mich
          auf die Zusammen&shy;arbeit. Schauen Sie sich doch gerne um oder
          kontaktieren Sie mich direkt.
        </JustifyParagraph>
        <RightParagraph>
          <BlackLink to="/about-me">Mehr &uuml;ber mich &hellip;</BlackLink>
        </RightParagraph>
      </Segment>
    </LayoutSection>

    {/* Contact */}
    <LayoutSection color={secondaryLayoutColor} header="Kontakt" text>
      <Grid columns={2} stretched>
        {[
          { icon: 'map', content: <p>Eldenaer Str. 6, 10247 Berlin</p> },
          {
            icon: 'mobile alternate',
            content: <Obfuscate tel="(+49) 0174 / 25 40 428" />,
          },
          {
            icon: 'phone',
            content: <Obfuscate tel="(+49) 030 / 79 74 04 81" />,
          },
          {
            icon: 'mail',
            content: (
              <Obfuscate
                email="ronald.steinke@steinke-it-service.de"
                headers={{
                  subject: 'Kontaktanfrage',
                }}
              />
            ),
          },
          {
            icon: 'linkedin',
            content: (
              <a href="https://www.linkedin.com/in/ronald-steinke">
                ronald-steinke
              </a>
            ),
          },
        ].map((row, i) => {
          return (
            <CompactRow key={i} centered>
              <Grid.Column width={2} verticalAlign="middle">
                <Segment basic size="huge" textAlign="right">
                  <p>
                    <Icon name={row.icon} />
                  </p>
                </Segment>
              </Grid.Column>
              <Grid.Column
                computer={11}
                tablet={11}
                only="tablet computer"
                verticalAlign="middle"
              >
                <Segment basic size="huge" textAlign="left">
                  {row.content}
                </Segment>
              </Grid.Column>
              <Grid.Column mobile={14} only="mobile" verticalAlign="middle">
                <Segment basic size="large" textAlign="left">
                  {row.content}
                </Segment>
              </Grid.Column>
            </CompactRow>
          )
        })}
      </Grid>
    </LayoutSection>

    {/* Core Topics */}
    <LayoutSection color={primaryLayoutColor} header="Schwerpunkte" text>
      <Grid stackable columns={3} centered>
        <Grid.Column textAlign="center">
          <Segment basic>
            <Icon name="industry" size="massive" />
          </Segment>
          <Segment basic>
            <Header size="medium">Retrofitting</Header>
          </Segment>
        </Grid.Column>
        <Grid.Column textAlign="center">
          <Segment basic>
            <Icon name="chart line" size="massive" />
          </Segment>
          <Segment basic>
            <Header size="medium">Datenvisualisierung</Header>
          </Segment>
        </Grid.Column>
        <Grid.Column textAlign="center">
          <Segment basic>
            <Icon name="cogs" size="massive" />
          </Segment>
          <Segment basic>
            <Header size="medium">Plattformentwicklung</Header>
          </Segment>
        </Grid.Column>
      </Grid>
    </LayoutSection>

    {/* Projects */}
    <LayoutSection color={secondaryLayoutColor} header="Projektauswahl" text>
      <Grid divided="vertically">
        <Grid.Row>
          <Grid.Column width={4} only="computer tablet" verticalAlign="middle">
            <p>03/20 – 04/20</p>
          </Grid.Column>
          <Grid.Column width={2} only="mobile" verticalAlign="middle">
            <p>
              03
              <br />
              20 &mdash; 04
              <br />
              20
            </p>
          </Grid.Column>
          <Grid.Column computer={12} tablet={12} mobile={14}>
            <NoMarginSegment basic>
              <Header size="small">
                Entwicklung eines Dashboards zur
                Produktions&shy;&uuml;berwachung im Chemie&shy;sektor
              </Header>
            </NoMarginSegment>
            <NoMarginSegment basic size="small">
              <Header size="tiny">Aufgaben:</Header>
              <List bulleted>
                <List.Item>
                  Verarbeitung der Daten, die in JSON bereitgestellt worden
                  sind.
                </List.Item>
                <List.Item>
                  &Uuml;bertragung des Designs in SVG in HTML-Widgets mit Hilfe
                  der D3.js Bibliothek.
                </List.Item>
                <List.Item>
                  Dynamische &Auml;nderungen der Widgets anhand der Daten.
                </List.Item>
              </List>
            </NoMarginSegment>
            <NoMarginSegment basic size="small">
              <Header size="tiny">Technologien:</Header>
              <p>D3.js, HTML/Javascript/CSS, Web-Server</p>
            </NoMarginSegment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4} only="computer tablet" verticalAlign="middle">
            <p>01/20 – 02/20</p>
          </Grid.Column>
          <Grid.Column width={2} only="mobile" verticalAlign="middle">
            <p>
              01
              <br />
              20 &mdash; 02
              <br />
              20
            </p>
          </Grid.Column>
          <Grid.Column computer={12} tablet={12} mobile={14}>
            <NoMarginSegment basic>
              <Header size="small">
                Umsetzung eines Prototyps zur Evaluierung des oneM2M-Standards
                im Energie&shy;sektors (Sammeln der Daten von den
                Umspann&shy;werken)
              </Header>
            </NoMarginSegment>
            <NoMarginSegment basic size="small">
              <Header size="tiny">Aufgaben:</Header>
              <List bulleted>
                <List.Item>
                  Anpassungen an der IoT-Plattform (
                  <a href="https://www.openmtc.org/">OpenMTC</a>).
                </List.Item>
                <List.Item>
                  Bereitstellen der Adapter zur Interaktion zwischen FTP und der
                  IoT-Plattform.
                </List.Item>
                <List.Item>
                  Containerisierung (Docker) der Komponenten.
                </List.Item>
              </List>
            </NoMarginSegment>
            <NoMarginSegment basic size="small">
              <Header size="tiny">Technologien:</Header>
              oneM2M, Python, Docker-Container
            </NoMarginSegment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Segment basic>
        <RightParagraph>
          <BlackLink to="/projects">&Uuml;bersicht &hellip;</BlackLink>
        </RightParagraph>
      </Segment>
    </LayoutSection>

    {/* Main Skills */}
    <LayoutSection color={primaryLayoutColor} header="Erfahrung" text>
      <Segment basic>
        <Header>Berufserfahrung</Header>
        <List bulleted>
          <List.Item>
            Technische Teamleitung der IIoT-Gruppe beim Fraunhofer FOKUS
          </List.Item>
          <List.Item>
            Senior Experte f&uuml;r M2M/IoT und Edge Computing
          </List.Item>
        </List>
        <Header>Qualifikationen</Header>
        <List bulleted>
          <List.Item>
            5 Jahre Erfahrung in ICT Forschung & Entwicklung, Planung und
            Netzwerk-Design
          </List.Item>
          <List.Item>
            7 Jahre Erfahrung in Designen, Implementieren und Betreiben von
            IP-basierten M2M/IoT-Plattformen
          </List.Item>
          <List.Item>
            Mehr als 15 Jahre Erfahrung in Software-Entwicklung, Frameworks,
            Betriebssysteme und Administration
          </List.Item>
          <List.Item>
            Setup und Administration von M2M/IoT-Pr&uuml;fst&auml;nden
          </List.Item>
          <List.Item>
            Erfahrung in ETSI/M2M und oneM2M Standards, Software Defined
            Networking (SDN), Cloud and Edge Computing, Real Time Computing
          </List.Item>
          <List.Item>
            Teilnahme an verschiedenen M2M/IoT Plugtests (ETSI, oneM2M)
          </List.Item>
        </List>
        <Header>Projekterfahrung</Header>
        <List bulleted>
          <List.Item>
            Verantwortlich f&uuml;r Entwicklung von OpenMTC und OpenIoTFog
          </List.Item>
          <List.Item>Open Source-Launch der OpenMTC-Implementierung</List.Item>
          <List.Item>
            Nationale und internationale Forschungsprojekte (TRESCIMO, FIESTA,
            SiNSeWa, SmartOrchestra, DynaWater4.0, etc.)
          </List.Item>
          <List.Item>
            Entwicklung verschiedenster Demonstratoren in der Smart City und
            Automatisierungsdom&auml;ne
          </List.Item>
        </List>
      </Segment>
      <Segment basic>
        <RightParagraph>
          <BlackLink to="/skills">&Uuml;bersicht &hellip;</BlackLink>
        </RightParagraph>
      </Segment>
    </LayoutSection>

    {/* References */}
    {/* <LayoutSection color={secondaryLayoutColor} header="Referenzen"></LayoutSection> */}
  </Layout>
)

export default IndexPage
